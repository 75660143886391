const helper = require('../../../../utils/helper');
const ux = require('../../../../application-center/ux-tracking-center');

export const getCustomersTimelineImages = async (messageName) => {
  try {
    let url = '';
    let res = [];

    url =
      helper.getAPIHost() +
      '/api/Customer360/getCustomersTimelineImages?messageName=' +
      messageName;

    ux.logAction(
      'Customer TimeLine:getCustomersTimelineImages',
      'getCustomersTimelineImages',
      url,
    );
    await fetch(url, {
      method: 'GET',
      ...helper.apiHeaders(),
    })
      .then(async (response) => {
        res = await response.json();
      })
      .catch((err) => {
        ux.logError(
          'Customer TimeLine:getCustomersTimelineImages',
          'getCustomersTimelineImages',
          err,
          url,
        );
      });

    if (res?.status === 'OK' && res.result !== null) {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'No Data',
      };
    }
  } catch (error) {
    console.error(error);
    ux.logError(
      'Customer TimeLine:getCustomersTimelineImages',
      'getCustomersTimelineImages',
      error,
    );
    return {
      success: false,
      msg: 'Failed to get list',
    };
  }
};
