import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Select from 'react-select';
import { Button } from 'reactstrap';
import enums from '../../enums';
import SpinnerLoading from '../SpinnerLoading';

import './CommonComponent.scss';

export function ModalBtnClose({ onClick, ...props }) {
  let classDefinition = '';

  if (props.className) {
    classDefinition = `${props.className} modal-btn-close`;
    delete props.className;
  } else {
    //Default class definition
    classDefinition = 'modal-btn-close';
  }

  return (
    <Button className={classDefinition} outline onClick={onClick} {...props}>
      <MdClose size={20}></MdClose>
    </Button>
  );
}

export function Dropdown({
  id,
  placeholder,
  value,
  options,
  onChange,
  isInvalid,
  isSearchable,
  isClearable,
  ...props
}) {
  let dropdownStyle = {
    menu: (provided, state) => ({
      ...provided,
      width: 'max-content',
      minWidth: '228px',
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      background: state.isSelected ? '#FF7285' : '#fff',

      '&:hover': {
        background: state.isSelected ? '#FF7285' : '#F9EBE9',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,

      svg: {
        color: '#303030',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: isInvalid ? '#d0281b !important' : '#D7DAE2 !important',
      border: '1px solid #D7DAE2',
      boxShadow: '#FF7285',
      height: '45px',
      minWidth: '200px',
      width: 'auto',

      '&:hover': {
        borderColor: '#D7DAE2',
        border: '1px solid #D7DAE2',
        boxShadow: '#D7DAE2',
      },
    }),
  };

  return (
    <div className="dropdown-select">
      <Select
        id={id}
        placeholder={placeholder ? placeholder : 'Please select one'}
        value={value}
        onChange={onChange}
        options={options.map((item) => {
          return { label: item.label, value: item.value };
        })}
        isSearchable={isSearchable}
        isClearable={isClearable}
        styles={dropdownStyle}
        {...props}
      />
    </div>
  );
}

export function RedButton({
  label,
  outline,
  onClick,
  customStyle,
  isLoading,
  ...props
}) {
  return (
    <Button
      className={`button-red ${outline ? 'outline' : 'default'} ${customStyle}`}
      onClick={onClick}
      {...props}
    >
      {isLoading ? (
        <div>
          <SpinnerLoading showLoadingText={true} />
        </div>
      ) : (
        label
      )}
    </Button>
  );
}

export function GreyButton({ label, outline, onClick, ...props }) {
  return (
    <Button
      className={`button-red ${outline ? 'grey' : 'default'}`}
      onClick={onClick}
      {...props}
    >
      {label}
    </Button>
  );
}

export function CustomAutocomplete({
  options,
  placeholder,
  inputValue,
  onInputChange,
  className,
  callback,
  ...props
}) {
  const [selectedData, setSelectedData] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(options);
  }, [options]);

  useEffect(() => {
    if (inputValue !== undefined) {
      setSelectedData(inputValue);
    }
  }, [inputValue]);

  const handleSearchKey = (event, value) => {
    let eventKey = event.target.value;
    let updateSelected = value?.length > 0 ? value : eventKey;

    if (value?.length === 0 && eventKey === undefined) {
      value = '';
      eventKey = '';
      updateSelected = '';
    }

    // console.log("-----------------------------")
    // console.log("Value", value)
    // console.log("Event Key", eventKey)
    // console.log("Value to update", updateSelected)

    if (event.target instanceof HTMLInputElement) {
      //If user type, don't callback first until user click the list
      setSelectedData(updateSelected);

      if (event.charCode === 13) {
        window.message.notification(
          'Please select one of the options',
          enums.notificationType.W,
        );
      } else if (event.charCode === 0) {
        callback(updateSelected); //If selected with dropdown, callback
      }
      return;
    }

    if (
      (updateSelected !== undefined && eventKey === 0) ||
      (eventKey === '' && updateSelected === '') ||
      (eventKey.length > 0 && updateSelected.length > 0 && value !== undefined)
    ) {
      setSelectedData(updateSelected);
      callback(updateSelected);
    }
  };

  return (
    <div className="custom-common-autocomplete">
      <Autocomplete
        className={className}
        options={data}
        placeholder={placeholder ? placeholder : 'Search'}
        freeSolo
        inputValue={selectedData}
        onInputChange={(event, value) => handleSearchKey(event, value)}
        {...props}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder ? placeholder : 'Search'}
            onKeyPress={(event, value) => handleSearchKey(event, value)}
            {...params}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
