import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  FormGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  Divider,
} from '@mui/material';
import { RedButton } from '../../../components/CommonComponent';

import './disposition-question.scss';
const helper = require('../../../utils/helper');

const UserQuestionView = ({
  showModal,
  toggleModal,
  questions = [],
  opportunityTicket,
  refreshData,
  isFromDashboard = false,
}) => {
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    console.log('Questions passed to UserQuestionView:', questions);
    console.log('Ticket passed to UserQuestionView:', opportunityTicket);

    if (questions && questions.length > 0) {
      const initialAnswers = questions.reduce((acc, question) => {
        // Array answer
        if (question.questionType === 'multiple-check') {
          acc[question.questionID] = Array.isArray(question.answer)
            ? question.answer
            : question.answer
              ? question.answer.split(',').map((ans) => ans.trim())
              : [];
        } else {
          // String answer
          acc[question.questionID] = question.answer || '';
        }
        return acc;
      }, {});
      setAnswers(initialAnswers);
    }
  }, [questions, opportunityTicket]);

  const handleInputChange = (uid, option, checked) => {
    if (checked === undefined) {
      // Single choice
      setAnswers((prev) => ({ ...prev, [uid]: option }));
    } else {
      // Multiple choice
      setAnswers((prev) => {
        const currentAnswers = prev[uid] ? [...prev[uid]] : [];
        if (checked) {
          if (!currentAnswers.includes(option)) {
            return { ...prev, [uid]: [...currentAnswers, option] };
          }
        } else {
          const updatedAnswers = currentAnswers.filter(
            (item) => item !== option,
          );
          return { ...prev, [uid]: updatedAnswers };
        }
        return prev;
      });
    }
  };

  const questionSubmit = () => {
    return questions.every((question) => {
      if (question.isRequired) {
        const answer = answers[question.questionID];

        return Array.isArray(answer)
          ? answer.length > 0
          : answer?.trim().length > 0;
      }
      return true;
    });
  };

  const handleSubmit = async () => {
    if (questionSubmit()) {
      try {
        const dataToSubmit = {
          opportunityTicket,
          questions: questions.map((question) => ({
            questionID: question.questionID,
            answers: Array.isArray(answers[question.questionID])
              ? answers[question.questionID]
              : [answers[question.questionID]] || [],
          })),
        };

        console.log('Payload to submit:', dataToSubmit);

        const response = await fetch(
          helper.getAPIHost() +
            '/api/OpportunityList/SaveOpportunityQuestionAnswer',
          {
            method: 'POST',
            body: JSON.stringify(dataToSubmit),
            ...helper.apiHeaders(),
          },
        );

        const result = await response.json();
        if (result.errorCode === 200) {
          console.log('Answers saved successfully:', result);

          if (refreshData) {
            refreshData();
          }
        } else {
          console.error('Failed to save answers:', result.errorMsg);
        }
      } catch (error) {
        console.error('Error while saving answers:', error);
      }

      toggleModal();
    } else {
      console.error('Please fill in all required fields.');
    }
  };

  const renderQuestion = (question, index) => {
    console.log('Rendering question:', question);
    const questionNumber = index + 1;

    switch (question.questionType) {
      case 'multiple-choice':
        return (
          <FormGroup key={question.questionID}>
            <span
              className={`question-text ${question.isRequired ? 'required' : ''}`}
            >
              {questionNumber}. {question.questionText}
            </span>
            {question.questionOptions.map((option) => (
              <FormControlLabel
                key={option.uid}
                control={
                  <Radio
                    checked={answers[question.questionID] === option.option}
                    onChange={() =>
                      handleInputChange(question.questionID, option.option)
                    }
                  />
                }
                label={option.option}
              />
            ))}
          </FormGroup>
        );
      case 'multiple-check':
        return (
          <FormGroup key={question.questionID}>
            <span
              className={`question-text ${question.isRequired ? 'required' : ''}`}
            >
              {questionNumber}. {question.questionText}
            </span>
            {question.questionOptions.map((option) => (
              <FormControlLabel
                key={option.uid}
                control={
                  <Checkbox
                    checked={
                      answers[question.questionID]?.includes(option.option) ||
                      false
                    }
                    onChange={(e) =>
                      handleInputChange(
                        question.questionID,
                        option.option,
                        e.target.checked,
                      )
                    }
                  />
                }
                label={option.option}
              />
            ))}
          </FormGroup>
        );
      case 'text-entry':
        return (
          <FormGroup key={question.questionID}>
            <span
              className={`question-text ${question.isRequired ? 'required' : ''}`}
            >
              {questionNumber}. {question.questionText}
            </span>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              placeholder="Please start typing..."
              value={answers[question.questionID] || ''}
              onChange={(e) =>
                handleInputChange(question.questionID, e.target.value)
              }
            />
          </FormGroup>
        );
      default:
        return (
          <span
            className={`question-text ${question.isRequired ? 'required' : ''}`}
          >
            {question.questionType}
          </span>
        );
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          toggleModal();
        }
      }}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        top: '2%',
        position: 'absolute',
        zIndex: (theme) => theme.zIndex.tooltip + 1,
      }}
    >
      <Box className="user-question-modal">
        <div className="modal-header">
          <h5>Disposition Questions</h5>
          <Divider orientation="vertical" flexItem />
        </div>
        <div className="modal-body">
          {questions.length > 0 && questions.map(renderQuestion)}
          <div className="user-question-modal-button">
            <RedButton
              label={isFromDashboard ? 'Close' : 'Skip for Now'}
              onClick={toggleModal}
              customStyle="skip-for-now-button"
              disabled={!isFromDashboard && !questionSubmit()}
            />
            <RedButton
              label="Submit"
              onClick={handleSubmit}
              disabled={!questionSubmit()}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UserQuestionView;
