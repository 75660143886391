import React, { createContext, useState } from 'react';
const helper = require('../../utils/helper');

const currentUserInformation = {
  empId: localStorage.getItem('EmplId'),
  empName: localStorage.getItem('EmplName'),
  empJobTitle: localStorage.getItem('JobTitle'),
  storeId: localStorage.getItem('StoreId'),
  storeName: localStorage.getItem('StoreName'),
  isManager:
    [2, 3, 26].includes(helper.getUserGroup()) ||
    (helper.getSnrGXSJobTitles().includes(localStorage.getItem('JobTitle')) &&
      helper.getUserGroup() === 6),
  isAdmin: localStorage.getItem('isAdmin') === 'true',
  isSnrGXS: helper.getUserGroup() === 26,
  isAssociate: helper.getUserGroup() === 6,
};

export const OpportunityContext = createContext(undefined);

export const OpportunityProvider = ({ children }) => {
  const [notepadInfo, setNotepadInfo] = useState({});
  const [twoMinDrillEmpId, setTwoMinDrillEmpId] = useState(null);

  const updateNotepadInfo = (newInfo) => {
    setNotepadInfo((prev) => ({ ...prev, ...newInfo }));
  };

  const updateTwoMinDrillEmpId = (empId) => {
    setTwoMinDrillEmpId(empId);
  };

  return (
    <OpportunityContext.Provider
      value={{
        currentUserInformation,
        notepadInfo,
        updateNotepadInfo,
        twoMinDrillEmpId,
        updateTwoMinDrillEmpId,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};
