import Avatar from '../components/Avatar/index';
import bobboostLogo from '../assets/img/logo/bobboost.PNG';
import { UserCard } from '../components/Card';
import React from 'react';
import { MdExitToApp, MdEdit } from 'react-icons/md';
import { BiDollarCircle } from 'react-icons/bi';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from 'reactstrap';
import { Checkbox } from '@mui/material';
import * as auth from '../application-center/login-center/lib/authentication';
import bn from '../utils/bemnames';
import BDFGauge from '../bdf-applications/dashboard-elements/gauges';
import { MdSwapHoriz } from 'react-icons/md';
import PageSpinner from '../components/PageSpinner';
//import BDFNotification from '../application-center/notification-center';
//import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import BusinessModule from '../bdf-applications/business-module/BusinessModule';
import { Link } from 'react-router-dom';
import { ModalBtnClose } from '../components/CommonComponent';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { CommunicationCollaborationScheduler } from '../bdf-applications/communication-collaboration/scheduler';
import HostOnDuty, { DeleteHostOnDuty } from '../bdf-applications/host-duty';
import { DIVStoreGuideIcon } from '../components/StoreGuideIcon';

const bem = bn.create('header');
const helper = require('../utils/helper');
const enums = require('../enums');
const ux = require('../application-center/ux-tracking-center');
// const privacy = require('../../classes/viewPrivacy');
const initData = require('../application-center/login-center/lib/dataInitialization');

//BCP-2283 : UI Scale Adjustment
const minZoom = 80;
const maxZoom = 90;

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isOpenUserCardPopover: false,
    showGaugeDrawer: false,
    showGaugeHeader: true,
    tempShowGaugeHeader: true,
    userPreferences: {
      writtenSalesMetric: false,
      deliveredSalesMetric: false,
      netPromoterScoreMetric: false,
      protectorAttachMetric: false,
      guardianAttachMetric: false,
      totalWellsAppsMetric: false,
      salesPerCallHandledMetric: false,
    },
    tempUserPreferences: {},

    isTabletView: false,
    isOutletView: false,

    // notification
    isNotificationOpen: false,

    //Modal
    showModal: false,

    //StoreChange
    loginProcess: false,
    loginUserName: '',
    loginEmplId: localStorage.getItem('EmplId') || '',
    loginStoreId: '',
    isFloater: localStorage.getItem('isFloater') || false,
    errorMessage: '',
    loginRegion: '',

    // AssociateViewName:
    //   localStorage.getItem('UserGroupId') === '26'
    //     ? 'Associate View'
    //     : 'Manager View',

    //variableforstore
    AllStore: [],
    Allregion: [],

    keyword: '',

    isSpecialGroup: localStorage.getItem('UserGroupId') === '12',

    //BCP-2283 : UI Scale Adjustment
    zoomValue: parseInt(localStorage.getItem('zoomLevel')),
  };

  componentDidMount() {
    const savedPreferences =
      JSON.parse(localStorage.getItem('userPerferences')) || {};
    const savedGaugeHeader =
      localStorage.getItem('gaugeMetricsHeader') === 'true';

    this.setState({
      userPreferences: savedPreferences,
      tempUserPreferences: { ...savedPreferences },
      showGaugeHeader: savedGaugeHeader,
      tempShowGaugeHeader: savedGaugeHeader,
    });

    //get all region names
    this.getAllRegionName();
    this.refreshZoomValue();

    if (
      parseInt(
        JSON.parse(localStorage.getItem('Parameters'))?.UI_CNC_SCHEDULER,
      ) !== -1
    ) {
      CommunicationCollaborationScheduler();
    }
  }

  closeAllPopover = (e) => {
    console.log(e.target);
    e.preventDefault();
    this.setState(
      {
        isNotificationOpen: false,
        isOpenUserCardPopover: false,
      },
      () =>
        document
          .getElementById('root')
          .removeEventListener('click', this.closeAllPopover),
    );
  };

  toggleGaugeDrawer = () => {
    this.setState((prevState) => {
      if (!prevState.showGaugeDrawer) {
        // use saved state, if reopen
        return {
          showGaugeDrawer: true,
          tempShowGaugeHeader: this.state.showGaugeHeader,
          tempUserPreferences: { ...this.state.userPreferences },
        };
      } else {
        return { showGaugeDrawer: false };
      }
    });
  };

  toggleGaugeHeader = () => {
    this.setState((prevState) => ({
      tempShowGaugeHeader: !prevState.tempShowGaugeHeader,
    }));
  };

  toggleOutletView = () => {
    this.setState((prevState) => ({
      isOutletView: !prevState.isOutletView,
    }));
  };

  toggleUserCardPopover = (e) => {
    e.preventDefault();
    this.setState(
      {
        isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
        isNotificationOpen: false,
      },
      () => {
        if (this.state.isOpenUserCardPopover)
          document
            .getElementById('root')
            .addEventListener('click', this.closeAllPopover);
        else
          document
            .getElementById('root')
            .removeEventListener('click', this.closeAllPopover);
      },
    );
  };

  toggleNotificationPopover = (e) => {
    e.preventDefault();
    this.setState(
      {
        isNotificationOpen: !this.state.isNotificationOpen,
        isOpenUserCardPopover: false,
      },
      () => {
        if (this.state.isNotificationOpen)
          document
            .getElementById('root')
            .addEventListener('click', this.closeAllPopover);
        else
          document
            .getElementById('root')
            .removeEventListener('click', this.closeAllPopover);
      },
    );
  };

  refreshPage() {
    let view = localStorage.getItem('BobSquidManagerView');
    if (view === 'true') {
      localStorage.setItem('BobSquidManagerView', false);
    } else {
      localStorage.setItem('BobSquidManagerView', true);
    }

    window.location.reload(false);
  }

  toggleTablet = () => {
    window.open('/tabletviewhelp', '_blank');
  };

  changeStore = () => {
    console.log('change Store');
    let showModal = !this.state.showModal;

    this.setState({
      showModal: showModal,
    });
  };

  changeStorecheat = () => {
    console.log('nonthing to do here!!');
  };

  handleStoreChange = (event) => {
    let val = event.target.value.replace(/[^\d.-]/g, '');
    this.setState({
      loginStoreId: val,
      loginUserName: '',
      loginEmplId: localStorage.getItem('EmplId'),
    });
  };

  handleRegionChange = (event) => {
    let val = event.target.value || '';
    let loginRegion = '';

    loginRegion = val;

    console.log(loginRegion);
    this.setState(
      {
        loginRegion: loginRegion,
      },
      () => this.getAllStoresName(loginRegion),
    );
  };

  handleSubmit = () => {
    if (this.state.loginStoreId.trim().length > 0) {
      this.setState({ loginProcess: true, errorMessage: '' });

      let username = this.state.floaterUserId;
      let empId = this.state.loginEmplId;
      if (empId !== '') {
        username = '';
      }

      console.log('Landing Floater');
      console.log('EmpId ' + empId);
      console.log('username ' + username);
      console.log('Store ' + this.state.loginStoreId);

      if (
        helper.getQSParam('floaterId') !== null &&
        helper.getQSParam('floaterId') !== ''
      ) {
        username = helper.getQSParam('floaterId');
        console.log('impersonate to floaterId ' + username);
      }
      // let associatesView = localStorage.getItem('UserGroupId') === '26';
      // let associatesView = this.state.AssociateViewName === 'Associate View';

      let associatesView = false;
      let isSeniorGxs = localStorage.getItem('isSeniorGXS') === 'true';

      if (isSeniorGxs) {
        associatesView = localStorage.getItem('UserGroupId') !== '26';
      }

      auth
        .doLogin(
          username,
          '',
          true,
          empId,
          this.state.loginStoreId.trim(),
          associatesView,
        )
        .then((result) => {
          if (result.status) {
            console.log('Login success');
            localStorage.setItem('FloaterID', username);
            Promise.all([
              DeleteHostOnDuty(),
              initData.initFiscalMonth(),
              initData.initDashboardKPI(),
              initData.initFeatureReleaseNotes(),
              // if more data need to init, append here
            ]).then(([ret1, ret2]) => {
              if (ret1 && ret2) console.log('ok');
              window.location.href = '/home';
            });
          } else {
            console.log('Login failed');

            this.setState({
              errorMessage:
                'Your user account is not in a security group with access to BobBoost. Ext. 01555',
              loginProcess: false,
            });
          }
        });
    } else {
      this.setState({ errorMessage: 'Please pick a Store !' });
    }
  };

  handlecheckbox = (selected) => {
    let loginRegion = '';
    if (this.state.loginRegion === '') {
      loginRegion = selected[0];
    } else {
      loginRegion = selected[1];
    }

    if (selected.length === 0) {
      loginRegion = '';
    }

    this.setState(
      {
        loginRegion: loginRegion,
      },
      () => this.getAllStoresName(loginRegion),
    );
  };

  getAllStoresName = async (loginRegion) => {
    let storeName = [];
    let region = '';

    if (loginRegion !== '') {
      // region = encodeURIComponent(loginRegion.value.trim());
      region = encodeURIComponent(loginRegion.trim());
    }

    try {
      let url =
        helper.getAPIHost() +
        '/api/MDStoreDirectories/getAllStoresName?region=' +
        region;
      console.log(url);
      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            console.log('Store data');
            console.log(data.result);
            storeName = data.result;
          } else {
            console.log(
              'Header',
              'Get Data from API',
              'Unable to get data from server!',
              url + ' ' + data.errorMsg,
            );
            // ux.logError("Customer350Home", "Get Data from API", "Unable to get data from server!", url + " " + data.errorMsg);
          }
        })
        .catch((error) => {
          console.log(
            'Header',
            'Get Data from API',
            'Init Data API:GetAllStoresName - ' + error,
            url,
          );
          // ux.logError("Customer350Home", "Get Data from API", "Init Data API:GetAllStoresName - " + error, url);
        });
    } catch (error) {
      console.log(
        'Header',
        'Get Data from API',
        'Init Data API: GetAllStoresName - ' + error,
      );
      // ux.logError("Customer350Home", "Get Data from API", "Init Data API: GetAllStoresName - " + error);
    }

    // return storeName;
    this.setState({
      AllStore: storeName,
    });
  };

  getAllRegionName = async () => {
    let region = [];
    let loginRegion = '';

    try {
      let url =
        helper.getAPIHost() + '/api/MDStoreDirectories/getAllRegionsName';
      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            console.log('Region data');
            console.log(data.result);
            helper.catchServer(data);

            data.result.map((val) => {
              if (localStorage.getItem('EmplId') === val.empid) {
                // console.log(loginRegion+"=="+val.empid)
                loginRegion = val.region;
              }
              region.push({ label: val.region, value: val.region });
              return val;
            });
            // storeName = data.result;
          } else {
            console.log(
              'Header',
              'Get Data from API',
              'Unable to get data from server!',
              url + ' ' + data.errorMsg,
            );
            // ux.logError("Customer350Home", "Get Data from API", "Unable to get data from server!", url + " " + data.errorMsg);
          }
        })
        .catch((error) => {
          console.log(
            'Header',
            'Get Data from API',
            'Init Data API:getAllRegionsName - ' + error,
            url,
          );
          // ux.logError("Customer350Home", "Get Data from API", "Init Data API:GetAllStoresName - " + error, url);
        });
    } catch (error) {
      console.log(
        'Header',
        'Get Data from API',
        'Init Data API: getAllRegionsName - ' + error,
      );
      // ux.logError("Customer350Home", "Get Data from API", "Init Data API: GetAllStoresName - " + error);
    }

    this.setState(
      {
        Allregion: region,
        loginRegion: loginRegion,
      },
      () => this.getAllStoresName(loginRegion),
    );
  };

  handleInputChanged = (event) => {
    let val = event.target.value;
    this.setState({
      keyword: val,
    });
  };

  handleInputEnter = (event) => {
    if (event.charCode === 13) {
      if (this.state.keyword?.trim() !== '') {
        this.handleSearch();
      }
    }
  };

  handleSearch = () => {
    window.redirect(`/merchandise?search=${this.state.keyword}`);
    this.setState({
      keyword: '',
    });
  };

  handleToggle = (metric) => {
    this.setState((prevState) => {
      const updatedPreferences = {
        ...prevState.tempUserPreferences,
        [metric]: !prevState.tempUserPreferences[metric],
      };

      return {
        tempUserPreferences: updatedPreferences,
      };
    });
  };

  handleSavePreferences = async () => {
    try {
      const { tempUserPreferences, tempShowGaugeHeader } = this.state;

      const payload = {
        ...tempUserPreferences,
        empID: helper.getEmplId(),
        gaugeMetricsHeader: tempShowGaugeHeader,
      };

      const response = await fetch(
        helper.getAPIHost() + '/saveMetricGaugePreferences',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          ...helper.apiHeaders(),
        },
      );

      const data = await response.json();

      if (data.status === 'OK') {
        console.log('Preferences saved successfully');
        localStorage.setItem(
          'userPerferences',
          JSON.stringify(tempUserPreferences),
        );
        localStorage.setItem('gaugeMetricsHeader', tempShowGaugeHeader);

        this.setState({
          userPreferences: tempUserPreferences,
          showGaugeHeader: tempShowGaugeHeader,
          showGaugeDrawer: false,
        });
      } else {
        console.error('Failed to save preferences:', data.errorMsg);
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  };

  updateZoom = (zoomIn) => {
    if (zoomIn) {
      //Zoom +
      if (this.state.zoomValue < maxZoom)
        this.setState({ zoomValue: this.state.zoomValue + 10 }, () =>
          UpdateZoomPreference(),
        );
    } else {
      //Zoom -
      if (this.state.zoomValue > minZoom)
        this.setState({ zoomValue: this.state.zoomValue - 10 }, () =>
          UpdateZoomPreference(),
        );
    }

    const UpdateZoomPreference = async () => {
      let payload = {
        empId: helper.getEmplId(),
        zoomLevel: this.state.zoomValue,
      };

      await fetch(helper.getAPIHost() + '/saveUserPreferences', {
        method: 'POST',
        body: JSON.stringify(payload),
        ...helper.apiHeaders(),
      })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            //Refresh document zoom level
            this.refreshZoomValue();
            localStorage.setItem('zoomLevel', this.state.zoomValue); //Update zoom value locally to avoid refresh issue
          } else {
            window.message.notification(
              'Failed to save zoom preference.',
              enums.notificationType.E,
            );
            ux.logError('Header', 'saveUserPreferences', data.errorMsg, '');
          }
        })
        .catch((error) => {
          window.message.notification(
            'Failed to save zoom preference.',
            enums.notificationType.E,
          );
          ux.logError('Header', 'saveUserPreferences', error.message, '');
        });
    };
  };

  refreshZoomValue = () => {
    let userProfile = document.getElementById('user-profile');

    if (userProfile) {
      //If profile open, then close it by click
      let avatar = document.getElementById('user-initial-avatar');
      avatar.click();
      setTimeout(() => {
        avatar.click();
      }, 200);
    }

    document.body.style.zoom = `${this.state.zoomValue}%`;

    let rootStyle = document.querySelector(':root');

    rootStyle.style.setProperty('--zoomLevel', `${this.state.zoomValue / 100}`);
  };

  switchToGSX = () => {
    this.setState({
      isOpenUserCardPopover: false,
      loginProcess: true,
      errorMessage: '',
    });

    let username = this.state.floaterUserId;
    let empId = this.state.loginEmplId;
    if (empId !== '') {
      username = '';
    }

    console.log('Landing Floater');
    console.log('EmpId ' + empId);
    console.log('username ' + username);
    console.log('Store ' + this.state.loginStoreId);

    if (
      helper.getQSParam('floaterId') !== null &&
      helper.getQSParam('floaterId') !== ''
    ) {
      username = helper.getQSParam('floaterId');
      console.log('impersonate to floaterId ' + username);
    }

    let associatesView = localStorage.getItem('UserGroupId') === '26';
    // let associatesView = this.state.AssociateViewName === 'Associate View';
    let isFloater = localStorage.getItem('isFloater') === 'true';
    let storeID = isFloater
      ? localStorage.getItem('StoreId')
      : this.state.loginStoreId.trim();

    console.log('Is Floater:', isFloater);
    console.log('Store ID used:', storeID);

    auth
      .doLogin(username, '', true, empId, storeID, associatesView)
      .then((result) => {
        if (result.status) {
          console.log('Login success');
          localStorage.setItem('FloaterID', username);
          console.log('StoreId after login:', localStorage.getItem('StoreId'));

          // if (result.isFloater) {
          //   localStorage.setItem('StoreId', storeID);
          // }
          Promise.all([
            // DeleteHostOnDuty(),
            initData.initFiscalMonth(),
            initData.initDashboardKPI(),
            initData.initFeatureReleaseNotes(),
            // if more data need to init, append here
          ]).then(([ret1, ret2]) => {
            if (ret1 && ret2) console.log('ok');
            window.location.href = '/home';
          });
        } else {
          console.log('Login failed');

          this.setState({
            errorMessage:
              'Your user account is not in a security group with access to BobBoost. Ext. 01555',
            loginProcess: false,
          });
        }
      });
  };

  isCommisionPermissibleByTitle = () => {
    const isValidJobTitles = () => {
      const notPermissibleJobTitle = [
        // 'Bobs Squad Associate',
        // 'Bobs Squad Associate I',
        // 'Bobs Squad Associate II',
        // 'Bobs Squad Director',
        'Bobs Squad National Sales Manager', //
        // 'Online Sales Support Associate',
        'Bobs Squad Online Sales Support Associate', //
        'Bobs Squad Online Chat Associate', //
        // 'Business Account Manager',
        // 'Business Account Executive',
        // 'Senior Business Account Executive',
        // 'Director Commercial Sales',
        'Bobs Squad Assistant Manager', //
      ];

      if (!notPermissibleJobTitle.includes(localStorage.getItem('JobTitle')))
        return true; //Return true if current JobTitle is NOT in the list
    };

    return isValidJobTitles();
  };

  isShowByTitleID = () => {
    const BobSquidManagerView =
      localStorage.getItem('BobSquidManagerView') === 'true';
    const squadStoreId = localStorage.getItem('squadStoreId') === 'true';
    const isSalesChat = localStorage.getItem('isSalesChat') === 'true';

    // Return true if none of the job titles are valid
    return !(BobSquidManagerView || squadStoreId || isSalesChat);
  };

  calculateVisibleMetricsCount = () => {
    const { tempUserPreferences } = this.state;

    const minMetricsGauge = parseInt(
      localStorage.getItem('minMetricsGauge'),
      10,
    );
    const maxMetricsGauge = parseInt(
      localStorage.getItem('maxMetricsGauge'),
      10,
    );

    const visibleMetrics = [
      tempUserPreferences.writtenSalesMetric,
      tempUserPreferences.deliveredSalesMetric,
      tempUserPreferences.netPromoterScoreMetric,
      tempUserPreferences.protectorAttachMetric,
      tempUserPreferences.guardianAttachMetric,
    ];

    if (
      helper.getUserGroup() === enums.userGroups.BobsSquad ||
      localStorage.getItem('BobSquidManagerView') === 'true'
    ) {
      visibleMetrics.push(tempUserPreferences.salesPerCallHandledMetric);
    } else {
      visibleMetrics.push(tempUserPreferences.totalWellsAppsMetric);
    }

    const visibleMetricsCount = visibleMetrics.filter(Boolean).length;

    return { visibleMetricsCount, minMetricsGauge, maxMetricsGauge };
  };

  render() {
    let view = localStorage.getItem('BobSquidManagerView');
    let squadStoreId = localStorage.getItem('squadStoreId');
    let ViewName = '';
    let squadDirector = localStorage.getItem('squadDirector');
    let isSeniorGxs = localStorage.getItem('isSeniorGXS');
    let isOutletManager = localStorage.getItem('isOutletManager');
    let AssociateViewName =
      localStorage.getItem('UserGroupId') === '26'
        ? 'Associate View'
        : 'Manager View';

    // let tabletViewBtn = this.state.isTabletView ? 'Normal View' : 'Tablet View';

    let storeInputProps = {
      id: 'storeId',
      type: 'select',
      placeholder: 'Store ID',
    };
    //helper.checkVersionScheduller();

    if (view === 'true') {
      ViewName = 'Manager View';
    } else {
      ViewName = "Bob's Squad View";
    }

    let DB = '';
    let locationName = '';
    try {
      if (localStorage.getItem('Parameters') !== '') {
        let param = JSON.parse(localStorage.getItem('Parameters'));
        if (param.DB) {
          DB = 'DB: ' + param.DB;
        }
        locationName = param?.SpecialGroupLocation || 'Home Office';
      }
    } catch (e) {
      // no param yet, not yet login
    }

    let showGaugeDrawer = this.state.showGaugeDrawer;
    let tempShowGaugeHeader = this.state.tempShowGaugeHeader;
    let tempUserPreferences = this.state.tempUserPreferences;
    let Allregion = this.state.Allregion;
    let AllStore = this.state.AllStore;

    const commissionDetailUserGroup = [1, 2, 3, 4, 5, 6, 7];

    const userGroup = helper.getUserGroup();

    const { visibleMetricsCount, minMetricsGauge, maxMetricsGauge } =
      this.calculateVisibleMetricsCount();

    console.log('Min Metrics Gauge:', minMetricsGauge);
    console.log('Max Metrics Gauge:', maxMetricsGauge);
    console.log('Visible Metrics Count:', visibleMetricsCount);

    const linkText =
      localStorage.getItem('JobTitle') === 'Retail Outlet Experience Lead' ||
      (userGroup !== 1 && userGroup !== 2)
        ? 'My Commissions'
        : 'Commission Summaries';

    const isB4B = localStorage.getItem('isB4B') === 'true';

    const isSalesChat = localStorage.getItem('isSalesChat') === 'true';

    return (
      <div className={bem.b()}>
        <Navbar
          light
          expand
          className={`iss-header ${this.state.isSpecialGroup ? 'special' : ''}`}
        >
          <img src={bobboostLogo} className="iss-logo" alt="" />

          <div className="next-to-logo">
            {/* Separator */}
            <span className="iss-header-separator">&nbsp;</span>

            {/* Store Name */}
            <span className="iss-header-storename">
              {this.state.isSpecialGroup
                ? localStorage.getItem('empClass') === 'MERCH'
                  ? 'Merchandising'
                  : locationName
                : // For normal group below
                view === 'true'
                ? isSalesChat
                  ? 'Sales Chat'
                  : "Bob's Squad"
                : localStorage.getItem('StoreName')}
            </span>

            {/* Environment tag */}
            {helper.getEnvironment() !== '' && (
              <div className="dev-tag">
                <div
                  className={`env environmentName ${helper.getEnvironment(1)}`}
                  title="This tag will only show in DEV & QA"
                >
                  {helper.getEnvironment()}
                </div>
                <div
                  className={`env environmentDB ${helper.getEnvironment(1)}`}
                  title=""
                >
                  {DB}
                </div>
              </div>
            )}

            {/* Switch Gxs View */}
            {isSeniorGxs === 'true' && (
              <span
                className="iss-header-switchview"
                onClick={this.switchToGSX}
              >
                <MdSwapHoriz /> Switch to: {AssociateViewName}
              </span>
            )}

            {/* Switch outlet View */}
            {isOutletManager === 'true' && (
              <span
                className="iss-header-switchview"
                onClick={this.toggleOutletView}
              >
                <MdSwapHoriz /> Switch to:{' '}
                {this.state.isOutletView ? 'Store Gauges' : 'Outlet Gauges'}
              </span>
            )}
          </div>

          {/* BCP-2799 : HOD not for Bob's Squad Manager */}
          {/* BCP-2769 : HOD not for Home Office */}
          {/* BCP-2809 : HOD not for Bob's Squad Associate/Sales Chat */}
          {/* BCP-2881 : HOD not for B4B in BobBoost */}
          {[
            enums.userGroups.SystemAdmin,
            enums.userGroups.StoreManager,
            enums.userGroups.SeniorGxs,
            enums.userGroups.Office,
            enums.userGroups.Outlet,
            enums.userGroups.Showroom,
            enums.userGroups.StoreSupport,
          ].includes(parseInt(localStorage.getItem('UserGroupId'))) &&
            helper.getStorelId() !== '1' &&
            ![
              'Bobs Squad Director',
              'Bobs Squad National Sales Manager',
              'Bobs Squad Associate',
              'Bobs Squad Associate I',
              'Bobs Squad Associate II',
              'Online Sales Support Associate',
              'Bobs Squad Online Sales Support Associate',
              'Bobs Squad Online Chat Associate',
              'Business Account Manager',
              'Business Account Executive',
              'Senior Business Account Executive',
              'Director Commercial Sales',
              'Bobs Squad Assistant Manager',
            ].includes(localStorage.getItem('JobTitle')) &&
            this.isShowByTitleID() && <HostOnDuty />}

          <Nav navbar className={bem.e('nav-right')}>
            <span
              className="iss-header-staffname cursor"
              onClick={this.toggleUserCardPopover}
            >
              {localStorage.getItem('EmplName')}{' '}
              <DIVStoreGuideIcon callerMenuCode={'Header'} />
            </span>

            <span
              className="opportunity-list-badge"
              id="opportunity-list-badge"
            >
              {/* Placeholder for logic in Opportunity List */}
            </span>

            {/*<BDFNotification caller={this} />*/}
            <BusinessModule caller={this} />

            <NavItem>
              <NavLink
                id="user-initial-avatar"
                onClick={this.toggleUserCardPopover}
              >
                <Avatar
                  className="can-click"
                  avatarInitial={localStorage.getItem('ProfileInitial')}
                  showArrowDown
                  style={{ fontSize: '0.9rem' }}
                />
              </NavLink>
              <Popover
                id="user-profile"
                placement="bottom-end"
                isOpen={this.state.isOpenUserCardPopover}
                //toggle={this.toggleUserCardPopover}
                target="user-initial-avatar"
                className="p-0 border-0 user-profile-popover"
                style={{ minWidth: 250 }}
              >
                <PopoverBody className="p-0 border-light">
                  <UserCard
                    title={localStorage.getItem('EmplName')}
                    subtitle={localStorage.getItem('JobTitle')}
                    text={localStorage.EmplId || ''}
                    className="border-light"
                    avatarInitial={localStorage.getItem('ProfileInitial')}
                  >
                    <ListGroup flush>
                      {/* Zoom */}
                      <ListGroupItem action className="">
                        {/* +20 to bring assumption to end user default is 100% when document zoom at 80% */}
                        <GiMagnifyingGlass /> Zoom:{' '}
                        {`${this.state.zoomValue + 20}%`}
                        <div className="zoom-section">
                          <button onClick={() => this.updateZoom(false)}>
                            -
                          </button>
                          <button onClick={() => this.updateZoom(true)}>
                            +
                          </button>
                        </div>
                      </ListGroupItem>

                      {/* My Commission */}
                      {
                        //Only for User Group outlined
                        //BCP-2504 : Not available for new store 348
                        //BCP-2933 : Not available for Polaris and Reynoldsburg store 388 390
                        //BCP-2672 : BobBoost Sales Chat configuration
                        commissionDetailUserGroup.includes(
                          helper.getUserGroup(),
                        ) &&
                        localStorage.getItem('StoreId') !== '348' &&
                        localStorage.getItem('StoreId') !== '388' &&
                        localStorage.getItem('StoreId') !== '390' &&
                        this.isCommisionPermissibleByTitle() &&
                        this.isShowByTitleID() &&
                        isB4B === false ? (
                          <ListGroupItem action className="">
                            <Link
                              to="/commission"
                              style={{
                                textDecoration: 'none',
                                color: '#212529',
                              }}
                            >
                              <BiDollarCircle /> {linkText}
                            </Link>
                          </ListGroupItem>
                        ) : null
                      }

                      {/* Change Store */}
                      {this.state.isFloater && !this.state.isSpecialGroup && (
                        <ListGroupItem
                          action
                          onClick={this.changeStore.bind(this)}
                          style={{ cursor: 'pointer' }}
                        >
                          <MdSwapHoriz /> Switch Store
                        </ListGroupItem>
                      )}

                      {/* Switch Bob's Squad Manager View */}
                      {squadStoreId === '100' &&
                        squadDirector === 'false' &&
                        false && (
                          <ListGroupItem
                            action
                            onClick={this.refreshPage}
                            style={{ cursor: 'pointer' }}
                          >
                            <MdSwapHoriz /> Switch to {ViewName}
                          </ListGroupItem>
                        )}

                      {!this.state.isSpecialGroup && (
                        <>
                          {!(
                            localStorage.getItem('StoreId') === '348' &&
                            localStorage.getItem('UserGroupId') !== '2' &&
                            localStorage.getItem('UserGroupId') !== '26' &&
                            localStorage.getItem('UserGroupId') !== '1'
                          ) && (
                            <ListGroupItem
                              action
                              onClick={this.toggleGaugeDrawer}
                              style={{
                                backgroundColor: this.state.showGaugeDrawer
                                  ? '#d4d4d4'
                                  : 'white',
                              }}
                            >
                              <MdEdit /> Gauges Setting
                            </ListGroupItem>
                          )}
                        </>
                      )}

                      {/* Signout */}
                      <ListGroupItem
                        tag="a"
                        action
                        className="border-light"
                        href="/logout"
                      >
                        <MdExitToApp /> Signout
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>

        <div id={'BDFGaugePages'}>
          {!this.state.isSpecialGroup && (
            <>
              {/* BCP-2543 : Hide for Assoc new store 348 */}
              {!(
                localStorage.getItem('StoreId') === '348' &&
                localStorage.getItem('UserGroupId') !== '2' &&
                localStorage.getItem('UserGroupId') !== '26' &&
                localStorage.getItem('UserGroupId') !== '1'
              ) && (
                <BDFGauge
                  userPreferences={this.state.userPreferences}
                  showGaugeHeader={this.state.showGaugeHeader}
                  isOutletView={this.state.isOutletView}
                />
              )}
            </>
          )}
        </div>

        {/* Modal for Customize Gauge Drawer */}
        <Modal
          isOpen={showGaugeDrawer}
          toggle={this.toggleGaugeDrawer}
          className="customize-gauge-modal"
        >
          <ModalHeader>
            Customize Gauges Header
            <ModalBtnClose onClick={this.toggleGaugeDrawer} />
          </ModalHeader>
          <ModalBody>
            {/* <div className="gauge-item">
              <span>Show/Hide Gauge Header</span>
              <Switch
                checked={tempShowGaugeHeader}
                onChange={this.toggleGaugeHeader}
              />
            </div> */}
            <div>
              <div className="gauge-item">
                <span>Written Sales</span>
                <Checkbox
                  checked={tempUserPreferences.writtenSalesMetric}
                  onChange={() => this.handleToggle('writtenSalesMetric')}
                />
              </div>
              <div className="gauge-item">
                <span>Delivered Sales</span>
                <Checkbox
                  checked={tempUserPreferences.deliveredSalesMetric}
                  onChange={() => this.handleToggle('deliveredSalesMetric')}
                />
              </div>
              <div className="gauge-item">
                <span>Net Promoter Score</span>
                <Checkbox
                  checked={tempUserPreferences.netPromoterScoreMetric}
                  onChange={() => this.handleToggle('netPromoterScoreMetric')}
                />
              </div>
              <div className="gauge-item">
                <span>Protector Attach</span>
                <Checkbox
                  checked={tempUserPreferences.protectorAttachMetric}
                  onChange={() => this.handleToggle('protectorAttachMetric')}
                />
              </div>
              <div className="gauge-item">
                <span>Guardian Attach</span>
                <Checkbox
                  checked={tempUserPreferences.guardianAttachMetric}
                  onChange={() => this.handleToggle('guardianAttachMetric')}
                />
              </div>
              {helper.getUserGroup() === enums.userGroups.BobsSquad ||
              view === 'true' ? (
                <div className="gauge-item">
                  <span>Sales Per Call Handled</span>
                  <Checkbox
                    checked={tempUserPreferences.salesPerCallHandledMetric}
                    onChange={() =>
                      this.handleToggle('salesPerCallHandledMetric')
                    }
                  />
                </div>
              ) : (
                <div className="gauge-item">
                  <span>Total Wells Apps</span>
                  <Checkbox
                    checked={tempUserPreferences.totalWellsAppsMetric}
                    onChange={() => this.handleToggle('totalWellsAppsMetric')}
                  />
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="gauge-footer">
              <div className="switch-container">
                <Checkbox
                  checked={tempShowGaugeHeader}
                  onChange={this.toggleGaugeHeader}
                />
                <span>Show/Hide Header</span>
              </div>

              {/* Save Button */}
              <button
                className="iss-button-red"
                onClick={() => {
                  if (
                    visibleMetricsCount < minMetricsGauge ||
                    visibleMetricsCount > maxMetricsGauge
                  ) {
                    window.message.notification(
                      `Please select at least ${minMetricsGauge} metrics to save.`,
                      'error',
                    );
                  } else {
                    this.handleSavePreferences();
                  }
                }}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showModal}
          toggle={
            !this.state.loginProcess
              ? this.changeStore.bind(this)
              : this.changeStorecheat.bind(this)
          }
          className="modalForComment"
        >
          {this.state.loginProcess === true && <PageSpinner />}
          <ModalHeader>
            {!this.state.loginProcess && (
              <ModalBtnClose onClick={this.changeStore.bind(this)} />
            )}
            {this.state.loginProcess && <ModalBtnClose disabled />}
            <div>
              <b>Change Store</b>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="region">
              <span>
                <b>Region: </b>
              </span>
              <div className="region-check">
                <Input
                  {...storeInputProps}
                  value={this.state.loginRegion}
                  onChange={this.handleRegionChange}
                >
                  <option id="0" value={''} region="">
                    Region
                  </option>
                  {Allregion.length > 0 &&
                    Allregion.map((item, i) => {
                      return (
                        <option
                          key={i}
                          id={i + 1}
                          value={item.value}
                          region={item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                </Input>
              </div>
            </div>
            <div className="store">
              <span>
                <b>Store Name: </b>
              </span>
              <span className="store-check">
                <Input
                  {...storeInputProps}
                  value={this.state.loginStoreId}
                  onChange={this.handleStoreChange}
                >
                  <option id="0" value={null} outlet="">
                    Store
                  </option>
                  {AllStore.length > 0 &&
                    AllStore.map((item, i) => {
                      return (
                        <option
                          key={i}
                          id={i + 1}
                          value={item.storeNumber}
                          outlet={item.outletNumber}
                        >
                          {item.store}
                        </option>
                      );
                    })}
                </Input>
              </span>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="iss-button-red"
              disabled={this.state.loginProcess}
              onClick={this.handleSubmit}
            >
              Login
            </button>
            <button
              className="iss-button-red"
              disabled={this.state.loginProcess}
              onClick={this.changeStore.bind(this)}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Header;
