/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, IconButton, Popover } from '@mui/material';

//Custom Component
import { HostOnDutyModal } from './host-duty-modal';
import { RedButton } from '../../components/CommonComponent';

//Context
import SignalRContext from '../../application-center/signalr-hub-center/singalr-context';

//SCSS
import './host-duty.scss';

//Enums
import enums from '../../enums';

//Icons
import { TiCancel } from 'react-icons/ti';

//Luxon
import { DateTime } from 'luxon';

//Helpers
const helper = require('../../utils/helper');
const ux = require('../../application-center/ux-tracking-center');

export default function HostOnDuty() {
  const mainConnection = useContext(SignalRContext);

  const [host, setHostData] = useState({});
  const [nextHost, setNextHost] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [connection, setConnection] = useState(null);
  const [connectionConnected, setConnectionConnected] = useState(false);
  const [firstConnection, setFirstConnection] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [openPopover, setPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [endTimerSet, setEndTimer] = useState(false);

  //Modal behavior states
  const [toExtend, setToExtend] = useState(false);
  const [toTakeOver, setToTakeOver] = useState(false);
  const [toAddNextShift, setToAddNextShift] = useState(false);

  //Modal force
  const [forceModal, setForceModal] = useState(false);
  const forceModalRef = useRef(forceModal);

  //First connection ref
  const firstConnectionRef = useRef(firstConnection);

  useEffect(() => {
    const connection = mainConnection; // Access the connection from the reference context

    if (connection && connection._connectionStarted) {
      console.log('Connected!', connection, connection.connectionId);
      setConnection(connection);
      setConnectionConnected(connection._connectionStarted);

      //Update Whole Floors for first connection
      connection.on('HostOnDuty', (hostData) => {
        let nextHostData = [];
        let skipHostCurrentConversion = false;

        if (hostData.next?.length > 0) {
          //Convert back from UTC to local
          nextHostData = hostData.next.forEach((x) => {
            x.endTime = convertToStoreTimeZone(x.endTime + 'Z');
            x.startTime = convertToStoreTimeZone(x.startTime + 'Z');
          });

          //Sort
          nextHostData = hostData.next.sort(
            (a, b) => a.startTime.toMillis() - b.startTime.toMillis(),
          );
        }

        if (!isStoreOpened() && !hostData.current && nextHostData?.length > 0) {
          // To display the earliest manager host when store haven't opened
          // hostData.current = nextHostData[0];
          skipHostCurrentConversion = true; //To avoid double conversion from nextHostData[0]
        }

        //Convert back from UTC to local
        if (!skipHostCurrentConversion && hostData.current) {
          hostData.current.endTime = convertToStoreTimeZone(
            hostData.current.endTime + 'Z',
          );
          hostData.current.startTime = convertToStoreTimeZone(
            hostData.current.startTime + 'Z',
          );
        }

        setHostData(hostData.current);
        setNextHost(nextHostData);
        console.log('Host On Duty', hostData.current);
        console.log('Next Host On Duty', nextHostData);

        if (
          hostData.current === null &&
          isHODManagerRoles() &&
          firstConnectionRef.current
        ) {
          // If current user is manager receiving this event when there is no current host from other users, ignore to avoid popup
          return;
        }

        checkIfShouldForceHostSetup(hostData.current, nextHostData);

        firstConnectionRef.current = true;
      });

      setTimeout(() => {
        setFirstConnection(true);
      }, 500);
    }
  }, [mainConnection]); // Add connectionRef to the dependencies array

  const GetHostOnDuty = useCallback(async () => {
    if (connectionConnected) {
      try {
        try {
          await fetch(
            helper.getSignalRHost() + `/GetHostOnDuty?now=${DateTime.utc()}`,
            {
              method: 'POST',
              ...helper.apiHeaders(),
            },
          );
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established to retrieve host on duty.',
        enums.notificationType.W,
      );
    }
  }, [connectionConnected]);

  const AddHostOnDuty = async (empId, startTime, endTime) => {
    if (connectionConnected) {
      let url = helper.getSignalRHost() + '/AddHostOnDuty';
      ux.logAction('Host and Duty', 'AddHostOnDuty', url);
      try {
        try {
          await fetch(helper.getSignalRHost() + '/AddHostOnDuty', {
            method: 'POST',
            body: JSON.stringify({
              EmployeeId: empId,
              store: localStorage.getItem('StoreId'),
              StartTime: startTime,
              EndTime: endTime,
              currentTime: DateTime.utc(),
            }),
            ...helper.apiHeaders(),
          }).then(async (res) => {
            let response = await res.json();
            if (response.errorMsg) {
              if (response && response.result && response.result.isOverlap) {
                let info = response.result; //Overlap information
                let message = `${
                  info.overlapName
                } is scheduled to be Host on Duty until ${convertToStoreTimeZone(
                  DateTime.fromISO(info.overlapEnd + 'Z'),
                ).toFormat('h:mm a')}. Please select a later time.`;

                window.message.notification(message, enums.notificationType.E);
              } else {
                window.message.notification(
                  response.errorMsg,
                  enums.notificationType.E,
                );
              }
            } else {
              setModalOpen(!openModal);

              if (forceModal) {
                // Reset force modal when submission made
                setForceModal(!forceModal);
              }
            }
          });
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established to retrieve.',
        enums.notificationType.W,
      );
    }
  };

  const TakeOverHostOnDuty = async (
    empId,
    startTime,
    endTime,
    takeOverEmpId,
  ) => {
    if (connectionConnected) {
      let url = helper.getSignalRHost() + '/TakeOverHostOnDuty';
      ux.logAction('Host and Duty', 'TakeOverHostOnDuty', url);
      try {
        try {
          await fetch(helper.getSignalRHost() + '/TakeOverHostOnDuty', {
            method: 'POST',
            body: JSON.stringify({
              EmployeeId: empId,
              store: localStorage.getItem('StoreId'),
              StartTime: startTime,
              EndTime: endTime,
              currentTime: DateTime.utc(),
              TakeoverEmployeeID: takeOverEmpId,
            }),
            ...helper.apiHeaders(),
          }).then((res) => {
            setModalOpen(!openModal);
          });
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established to retrieve.',
        enums.notificationType.W,
      );
    }
  };

  const RemoveHostOnDuty = async (empId) => {
    if (connectionConnected) {
      let url = helper.getSignalRHost() + '/RemoveHost';
      ux.logAction('Host and Duty', 'RemoveHost', url);
      try {
        try {
          await fetch(helper.getSignalRHost() + '/RemoveHost', {
            method: 'DELETE',
            body: JSON.stringify({
              employeeId: empId,
              currentTime: DateTime.utc(),
            }),
            ...helper.apiHeaders(),
          }).then(async (res) => {
            if (res.ok) {
              console.log('Record removed successfully');
            } else {
              let response = await res.json();
              console.error(`Error from ${res.url} --> ${response.errorMsg}`);
            }
          });
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established to retrieve.',
        enums.notificationType.W,
      );
    }
  };

  const isHODManagerRoles = () => {
    if (
      helper.getUserGroup() === 2 ||
      helper.getUserGroup() === 26 ||
      (helper.getSnrGXSJobTitles().includes(localStorage.getItem('JobTitle')) &&
        helper.getUserGroup() !== 6) //BCP-3080 : Senior GSX not able to schedule HOD in associate view
    ) {
      return true;
    }

    return false;
  };

  // First Connection
  useEffect(() => {
    if (firstConnection) {
      GetHostOnDuty();
      setFirstConnection(false);
    }
  }, [firstConnection]);

  // Every time open popover
  useEffect(() => {
    if (openPopover) GetHostOnDuty();
  }, [openPopover]);

  useEffect(() => {
    if (!openModal) {
      //To reset upon modal closed
      setToExtend(false);
      setToTakeOver(false);
      setToAddNextShift(false);
    } else if (openModal && openPopover) {
      setPopoverOpen(false);
    }
  }, [openModal]);

  useEffect(() => {
    if (toExtend || toTakeOver || toAddNextShift) {
      setModalOpen(!openModal);
    }
  }, [toExtend, toTakeOver, toAddNextShift]);

  useEffect(() => {
    //Timer setups
    const timerForNoCurrentHost = () => {
      if (!host && nextHost && nextHost?.length > 0) {
        //If current host is empty, but there is next host, schedule the next API call
        let firstNextHost = nextHost[0];
        const firstNextHostStartTime = firstNextHost.startTime.toMillis();
        const currentTime = convertToStoreTimeZone(DateTime.now()).toMillis();

        //Timeout timer
        const timeoutInMS = firstNextHostStartTime - currentTime;

        const timer = setTimeout(() => {
          firstConnectionRef.current = false; //Reset to allow null host data to be fetch again
          setTimeout(() => {
            GetHostOnDuty();
          }, 1000); //To wait ref update
        }, timeoutInMS);

        // Clear the timer if the component unmounts before the time difference is reached
        return () => clearTimeout(timer);
      }
    };

    const timerForCurrentHostEnd = () => {
      if (
        host &&
        host.employeeID === localStorage.getItem('EmplId') &&
        !endTimerSet
      ) {
        //If current host is exist schedule to call API after current host end
        const hostEndTime = host.endTime.toMillis();
        const currentTime = convertToStoreTimeZone(DateTime.now()).toMillis();
        setEndTimer(true); //To block multiple timeout setup

        //Timeout timer
        const timeoutInMS = hostEndTime - currentTime;

        if (!isStoreOpened()) {
          return;
        }

        const timer = setTimeout(() => {
          setEndTimer(false);
          GetHostOnDuty();
        }, timeoutInMS);

        // Clear the timer if the component unmounts before the time difference is reached
        return () => clearTimeout(timer);
      }
    };

    const timerForNoCurrentHostAndNextHostEveryHour = () => {
      // Check if both host and nextHost are empty
      if (!host && (!nextHost || nextHost.length === 0)) {
        // Get the current time
        const currentTime = convertToStoreTimeZone(DateTime.now());

        // Calculate the next hour
        const nextHour = currentTime.plus({ hours: 1 }).startOf('hour');

        // Calculate the difference in milliseconds between now and the next hour
        const timeoutInMS = nextHour.toMillis() - currentTime.toMillis();

        // Set a timeout to execute at the next hour
        const timer = setTimeout(() => {
          firstConnectionRef.current = false; //Reset to allow null host data to be fetch again
          setTimeout(() => {
            GetHostOnDuty();
          }, 1000); //To wait ref update
        }, timeoutInMS);

        // Clear the timer if the component unmounts before the time difference is reached
        return () => clearTimeout(timer);
      }
    };

    timerForNoCurrentHost();
    timerForCurrentHostEnd();
    timerForNoCurrentHostAndNextHostEveryHour();
  }, [connectionConnected, host, nextHost]);

  useEffect(() => {
    forceModalRef.current = forceModal;
  }, [forceModal]);

  const checkIfShouldForceHostSetup = (currentHostData, nextHostData) => {
    if (
      !isHODManagerRoles() ||
      helper.getSnrGXSJobTitles().includes(localStorage.getItem('JobTitle'))
    ) {
      //Only for store manager and GSA, else return
      return;
    }

    //If store is not yet opened and have next host, do not force to set
    if (!isStoreOpened() && nextHostData?.length > 0) return;

    //Force modal logic
    if (currentHostData && forceModalRef.current) {
      setForceModal(false);
      setModalOpen(false);
    } else if (!currentHostData) {
      //If there is no host for the next 5 minutes, force every manager to set current host
      if (nextHostData?.length > 0) {
        //Check next host time
        let nextHostDuty = nextHostData[0]; //This is correct since we sorted the next host by latest

        let nextHostDutyStartTime = convertToStoreTimeZone(
          nextHostDuty.startTime,
        ).toMillis();
        let currentTime = convertToStoreTimeZone(DateTime.now()).toMillis();
        const timeDifference = nextHostDutyStartTime - currentTime;

        let minutes = 5;
        let totalTimeInMs = minutes * 60 * 1000;

        if (timeDifference > totalTimeInMs) {
          setForceModal(true);
          setModalOpen(true);
        } else if (forceModalRef.current) {
          //Only close if its force modal
          setForceModal(false);
          setModalOpen(false);
        }
      } else {
        setForceModal(true);
        setModalOpen(true);
      }
    }
  };

  const nextHostPopupDisplay = () => {
    if (nextHost?.length > 0) {
      return (
        <div>
          <div>
            <strong>Next host:</strong>
          </div>

          <br />

          <div className="next-host-information-popup">
            {nextHost.map((next, index) => {
              return (
                <div key={next.employeeID + Math.random()}>
                  <span>
                    {index + 1}. {next.employeeName} from{' '}
                    {getTimeInAMPMFormat(next.startTime)} to{' '}
                    {getTimeInAMPMFormat(next.endTime)}
                  </span>
                  <IconButton
                    className="delete-icon"
                    aria-label="Remove shift"
                    disableRipple={true}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      RemoveHostOnDuty(next.employeeID);
                    }}
                  >
                    <TiCancel title="Remove shift" />
                  </IconButton>
                </div>
              );
            })}
          </div>
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div
        onClick={(e) => {
          if (!isHODManagerRoles()) {
            //Only for store manager and GSA, else return
            e.preventDefault();
            return;
          }

          if (
            host ||
            nextHost.some(
              (x) => x.employeeID === localStorage.getItem('EmplId'),
            )
          ) {
            // If current host exist or next host is current user
            setPopoverAnchor(e.currentTarget);
            setPopoverOpen(!openPopover);
          } else {
            // If current host exist or next host is not current user
            setModalOpen(true);
          }
        }}
        className="host-duty"
      >
        <div id="host-on-duty">
          <span className="current-host">
            <strong>
              Host on Duty:{' '}
              {host
                ? host.employeeName
                : nextHost.length > 0 && !isStoreOpened() //If no current host outside of store open hours, show next scheduled host
                ? nextHost[0].employeeName
                : ''}
            </strong>
          </span>
        </div>

        <Popover
          id={'host-on-duty-popover'}
          open={openPopover}
          anchorEl={popoverAnchor}
          onClose={() => setPopoverOpen(!openPopover)}
          disablePortal={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className="hod-popover">
            {host && host.employeeID === localStorage.getItem('EmplId') ? (
              <>
                {/* For Current HOD */}
                <div className="current-manager-host">
                  <div>
                    <strong>Your HOD Shift:</strong>
                  </div>

                  <br />

                  <div>
                    Host from {getTimeInAMPMFormat(host.startTime)} to{' '}
                    {getTimeInAMPMFormat(host.endTime)}
                  </div>

                  <br />

                  {nextHostPopupDisplay()}

                  <div>
                    <RedButton
                      label={'Extend my shift'}
                      onClick={() => {
                        setToExtend(true); //Update behavior to open modal in useEffect
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              // For other managers
              <div className="other-manager-host">
                {host && (
                  <strong>
                    Host until {getTimeInAMPMFormat(host?.endTime)}
                  </strong>
                )}
                <br />

                {/* Display own shift information */}
                {nextHost.some(
                  (x) => x.employeeID === localStorage.getItem('EmplId'),
                ) && (
                  <>
                    <span>
                      Your shift is from{' '}
                      {getTimeInAMPMFormat(
                        nextHost.find(
                          (x) =>
                            x.employeeID === localStorage.getItem('EmplId'),
                        ).startTime,
                      )}{' '}
                      to{' '}
                      {getTimeInAMPMFormat(
                        nextHost.find(
                          (x) =>
                            x.employeeID === localStorage.getItem('EmplId'),
                        ).endTime,
                      )}
                    </span>

                    <br />
                  </>
                )}

                {nextHostPopupDisplay()}

                <div>
                  {host && (
                    <RedButton
                      label={'Take over'}
                      onClick={() => {
                        setToTakeOver(true); //Update behavior to open modal in useEffect
                      }}
                    />
                  )}

                  {/* Only show add new host shift if own not already setup*/}
                  {!nextHost.some(
                    (x) => x.employeeID === localStorage.getItem('EmplId'),
                  ) &&
                    isStoreOpened() && (
                      <RedButton
                        label={'Add my host shift'}
                        onClick={() => {
                          setToAddNextShift(true); //Update behavior to open modal in useEffect
                        }}
                      />
                    )}
                </div>
              </div>
            )}
          </Box>
        </Popover>
      </div>

      <HostOnDutyModal
        addHostOnDutyFunc={AddHostOnDuty}
        takeOverHostOnDutyFunc={TakeOverHostOnDuty}
        getHostOnDutyFunc={GetHostOnDuty}
        isOpen={openModal}
        toggleModal={() => setModalOpen(!openModal)}
        hostData={host}
        nextHostData={nextHost}
        toExtendTime={toExtend}
        toTakeOver={toTakeOver}
        toAddNextShift={toAddNextShift}
        forceModalOpen={forceModal}
      />
    </>
  );
}

export const getTimeInAMPMFormat = (dateTime) => {
  if (!dateTime) {
    return null;
  }

  if (!dateTime.isLuxonDateTime) {
    dateTime = DateTime.fromISO(dateTime); //Parse to luxon format
  }

  let formattedAMPM = dateTime.toFormat('h:mm a');
  return formattedAMPM;
};

export const resetToCurrentHourMark = (datetime, isEndTime) => {
  let newDate;

  newDate = DateTime.fromISO(datetime ? datetime : DateTime.now()).set({
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (isEndTime) {
    newDate = add1HourToEndTime(newDate);
  }

  const convertedNewDate = convertToStoreTimeZone(newDate);

  return convertedNewDate;
};

export const add1HourToEndTime = (datetime) => {
  return convertToStoreTimeZone(
    DateTime.fromISO(datetime ? datetime : DateTime.now()).plus({
      hour: 1,
    }),
  );
};

export const getStoreHour = () => {
  const getCurrentDateWithTime = (timeStr) => {
    if (!/^([0-9]|1[0-2]):[0-5][0-9](am|pm)$/i.test(timeStr)) {
      throw new Error(`Invalid time format for Store Hour '${timeStr}'.`);
    }

    const now = convertToStoreTimeZone(DateTime.now());
    const [hours, minutes] = timeStr.match(/\d+/g).map(Number);
    const isPM = /pm/i.test(timeStr);

    // Calculate the correct hour in 24-hour format
    const adjustedHours = (hours % 12) + (isPM ? 12 : 0);

    // Set the hours and minutes, keeping the date parts the same
    const newDateTime = now.set({
      hour: adjustedHours,
      minute: minutes,
      second: 0,
      millisecond: 0,
    });

    return newDateTime;
  };

  let openHour = localStorage.getItem('storeOpenHour');
  let closeHour = localStorage.getItem('storeCloseHour');

  openHour = getCurrentDateWithTime(openHour);
  closeHour = getCurrentDateWithTime(closeHour);

  //Return Luxon DateTime
  return { openHour, closeHour };
};

export const isStoreOpened = () => {
  try {
    const { openHour, closeHour } = getStoreHour();
    const currentTime = convertToStoreTimeZone(DateTime.now());

    if (
      currentTime < openHour ||
      currentTime > closeHour ||
      localStorage.getItem('StoreId') === '1'
    ) {
      //Do not force the modal to setup a host
      console.log('Outside of store operation hour or store not supported');
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error('Store operation hours are not valid. ', error);
    return false;
  }
};

export const DeleteHostOnDuty = async () => {
  try {
    try {
      let url = helper.getSignalRHost() + '/DeleteHost';
      ux.logAction('Host and Duty', 'DeleteHost', url);
      await fetch(
        helper.getSignalRHost() + `/DeleteHost?now=${DateTime.utc()}`,
        {
          method: 'DELETE',
          ...helper.apiHeaders(),
        },
      ).then(async (res) => {
        if (res.ok) {
          console.log('Delete successfully');
        } else {
          let response = await res.json();
          console.error(`Error from ${res.url} --> ${response.errorMsg}`);
        }
      });
    } catch (e) {
      console.log('Sending message failed.', e);
    }
  } catch (e) {
    console.log(e);
  }
};

export const convertToStoreTimeZone = (datetime) => {
  let convertedTime = DateTime.fromISO(datetime).setZone(helper.getTimeZone());
  return convertedTime;
};
